@import url("./fonts/stylesheet.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.background {
		@apply w-screen h-screen fixed left-0 top-0 right-0 bottom-0;
	}
	.paper-cover {
		@apply 4xl:min-w-680 3xl:min-w-620 min-w-80 3xl:mt-3 3xl:h-full 3xl:max-h-55 absolute top-10 left-0 lg:pt-3 
    pt-0 justify-evenly flex flex-col m-auto max-w-lg w-full items-center h-4/5;
	}
	.logout-button {
		@apply text-gray-900 bg-gray-100  hover:bg-gray-200 hover:shadow focus:ring-4 focus:outline-none focus:ring-gray-100
     font-bold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center;
	}
	.close-button {
		@apply absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm
     p-1.5 ml-auto inline-flex items-center;
	}
	.delegate-button {
		@apply 3xl:h-16 px-4 3xl:w-60 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 border border-gray-800 sm:h-9 h-8 m-auto rounded-lg
     sm:text-xl text-base font-Nixie bg-buttonBg font-semibold;
	}
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
::-webkit-scrollbar-thumb {
	background-color: #c4c4c4;
	border-radius: 10px;
}
::-webkit-scrollbar-track {
	background-color: rgb(160, 150, 150);
	border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 1px solid #1d1a1a;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #c4c4c4;
	cursor: pointer;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
	border: 1px solid #1d1a1a;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #c4c4c4;
	cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
	border: 1px solid #1d1a1a;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #c4c4c4;
	cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
	visibility: hidden;
}
input[type="file"]::before {
	content: "Upload";
	height: 34px;
	display: inline-block;
	background: #c4c4c4;
	border: 1px solid #999;
	border-radius: 3px;
	outline: none;
	white-space: nowrap;
	cursor: pointer;
	font-weight: 500;
	font-size: 20px;
	width: 120px;
	float: right;
	padding-left: 20px;
}

input[type="file"]:active::before {
	background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

iframe {
	width: 0px;
	height: 0px;
	display: none;
}

/* ***************React toastify */
.Toastify__toast-theme--light {
	font-family: " Nixie One" !important;
	font-size: 1.2rem;
	padding: 0px !important;
	padding-left: 5px !important;
	margin: 0px !important;
	@media (min-width: 1920px) {
		font-size: 3rem;
	}
}

.Toastify__toast {
	max-height: 80px !important;
	min-height: 50px !important;
	min-width: 60px !important;
	width: 100%;

	@media (min-width: 1920px) {
		height: 200px;
		max-height: 160px !important;
		min-height: 150px !important;
		min-width: 120px !important;
	}
}
